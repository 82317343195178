import React, { useCallback } from "react";
import PropTypes from "prop-types";
import "./Loader.scss";
import LoaderGif from 'assets/icons/ava2.gif';
import PumaLoader from 'assets/icons/PumaLoader.gif';
import LinearProgress from '@mui/material/LinearProgress';
import { isTouchFriendly } from "utils";
import { useParams } from "react-router-dom";

const Loader = ({ children, className, onClick, setRef, progress = '', brand = '' }) => {
  let { domain } = useParams();
  const getLoader = useCallback(() => {
    const LoaderGifObj = {
      'puma': <div className="LoaderContainer"><img src={PumaLoader} alt="Loading" /></div>,
      'cava': <div className="CavaLoader"><img src={'http://d1pu2jrxdqoaw5.cloudfront.net/cava/cava.gif'} alt="Loading" /></div>,
      'beinghuman': <div className="LoaderContainer"><img src={'https://d1pu2jrxdqoaw5.cloudfront.net/beinghuman/plugindefaults/loader.gif'} alt="Loading" /></div>,
      'beinghumans': <div className="LoaderContainer"><img src={'https://d1pu2jrxdqoaw5.cloudfront.net/beinghuman/plugindefaults/loader.gif'} alt="Loading" /></div>,

    };
    const key = Object.keys(LoaderGifObj).find(key => {
      return domain === key;
    });
    if (key && LoaderGifObj[key]) {
      return LoaderGifObj[key];
    }
    return <div className="LoaderContainer"><img src={LoaderGif} alt="Loading" /></div>;
  }, []);

  const progressText = progress ? (Number(progress) < 99 ? `Loading assets ${progress}%` : 'Generating avatar...') : 'Loading...';
  return (
    <div className={`AppLoader ${className}`}>
      {getLoader()}
      <div style={{ height: '30px', width: '45%', textAlign: 'center' }}>
        <div className="AppLoader__Progress">{progressText}</div>
        {Number(progress) > 0 && <LinearProgress sx={{ width: '100%', zIndex: 1, position: 'relative', marginTop: '0.5em' }} variant="determinate" value={Number(progress)} />}
      </div>
    </div>
  );
};

Loader.propTypes = {
  children: PropTypes.node
};

Loader.defaultProps = {
  children: null
};

export default Loader;
