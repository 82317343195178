import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

const BrandThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('');

  // Update the theme by adding the respective class to the body element
  useEffect(() => {
    console.log(theme);
    document.body.className = theme;
  }, [theme]);

  const setThemeBrand = (brand) => {
    setTheme(`theme-${brand}`);
  };

  return (
    <ThemeContext.Provider value={{ theme, setThemeBrand }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default BrandThemeProvider;