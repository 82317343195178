import React from 'react';
import PropTypes from 'prop-types';
import './FloatingButtonBar.scss';

const FloatingButtonBar = props => {
  const { children, customClass, style } = props;
  return (
    <div
      className={`WO_OF_FloatingButtonContainer ${customClass ? customClass : ''}`}
      style={style}
    >
      {children}
    </div>
  );
};

FloatingButtonBar.defaultProps = { children: null };
FloatingButtonBar.propTypes = { children: PropTypes.node };

export default FloatingButtonBar;
