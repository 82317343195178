import React from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import './Button.scss';
import BubbleLoader from 'components/BubbleLoader';
const Button = ({
  children,
  text,
  onClick,
  disabled,
  isLoading,
  type,
  className,
}) => {
  const rootStyles = getComputedStyle(document.documentElement);
  const primaryColor = rootStyles.getPropertyValue('--primaryColor').trim();
  const handleClick = e => {
    if (!isLoading && !disabled && typeof onClick === 'function') {
      onClick(e);
    }
  };
  const buttonStyles = (type) => {
    switch (type) {
      case 'black-white': {
        return {
          background: primaryColor,
          border: '1px solid #fff',
          color: '#fff',
        }
      }
      case 'white-black-pure': {
        return {
          background: 'white',
          border: '1px solid #000',
          color: '#000',
        }
      }
      case 'white-black': {
        return {
          background: '#EEEEEE',
          color: primaryColor,
          border: '1px solid #888888',
        }
      }
      default: {
        return {
          background: primaryColor,
          border: '1px solid #fff',
          color: '#fff',
        }
      }
    }
  }
  const { background, color, border } = buttonStyles(type);
  const StyledMuiButton = styled(MuiButton)({
    boxShadow: 'none',
    textTransform: 'none',
    background: background,
    color: color,
    border: border,
    borderRadius: '3em',
    padding: '0.6em',
    margin: '0',
    '&:hover': {
      backgroundColor: background,
      border: border,
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: background,
      border: border,
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: background,
      border: border,
      boxShadow: 'none',
    },
  });

  return (
    <div className="Button__Container">
      <StyledMuiButton
        className={`${className} Button ${type} ${disabled && 'gray'} `}
        onClick={handleClick}
        disabled={disabled}
      >
        {isLoading ? (
          <BubbleLoader color={color} />
        ) : <>{children || text || 'Submit'}</>}
      </StyledMuiButton>
    </div>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  type: PropTypes.oneOf(['yellow-black', 'grey-white', 'blue-white', 'gray-blue', 'white-blue', 'black-white', 'white-black', 'google', 'white-black-pure']),
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};
Button.defaultProps = {
  children: null,
  type: 'orange-white',
  text: 'Submit',
  onClick: () => { },
  disabled: false,
  isLoading: false,
  className: '',
};

export default Button;
