import {
  SET_AVATAR_DATA,
  SET_AVATAR_IMAGE,
  UPLOAD_LOADER,
  SET_SELFIE_FILE,
  SET_PRODUCT_DATA,
  SET_LOADED_SCENE,
  SET_AVATAR_ASSETS,
  SET_IS_ANONYMOUS,
  SET_OLD_USER_ID,
  IS_DEFAULT_AVATAR,
  SET_DEFAULT_AVATAR_DATA,
  SET_TOKEN,
  SET_UID,
  SET_SESSION,
  SET_DEFAULT_SELFIE,
  SET_API_IMAGE,
  SET_INSTRUCTION_LIST,
  SET_SIZE_RECOMMENDATIONS,
  SET_FIRST_TIME_CREATION,
  RESET_AVATAR_STATE,
  ENABLE_ANALYTICS,
  SET_CART_DETAILS,
  SET_RELATED_BRANDS,
  SET_CATALOG_LIST,
} from "../actions/actionTypes";

const initialState = {
  avatar: "",
  imageUrl: "",
  selfie: "",
  defaultSelfie: "",
  userProfile: {},
  defaultUserProfile: {},
  isDefaultAvatar: false,
  product: {},
  loadedScene: null,
  avatarAssets: null,
  isAnonymous: true,
  oldUserId: '',
  token: '',
  uid: '',
  loadingCounter: 0,
  uploadLoader: false,
  apiImage: '',
  instructionCheckList: [],
  sizeRecommendations: {},
  firstTimeCreation: false,
  enableAnalytics: false,
  cartDetails: {},
  relatedBrands: [],
  catalogList: [],
  session: '',
  catalogList: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_AVATAR_DATA: {
      return { ...state, userProfile: { ...payload.userProfile, userAvatar: { ...payload.userProfile.userAvatar } } };
    }
    case SET_DEFAULT_AVATAR_DATA: {
      return { ...state, defaultUserProfile: { ...payload.defaultUserProfile, userAvatar: { ...payload.defaultUserProfile.userAvatar } } };
    }
    case IS_DEFAULT_AVATAR: {
      return { ...state, isDefaultAvatar: payload }
    }
    case SET_PRODUCT_DATA: {
      return { ...state, product: { ...payload } };
    }
    case SET_CATALOG_LIST: {
      return { ...state, catalogList: payload};
    }
    case SET_AVATAR_IMAGE: {
      return { ...state, imageUrl: payload };
    }
    case SET_SELFIE_FILE: {
      return { ...state, selfie: payload };
    }
    case UPLOAD_LOADER: {
      return { ...state, uploadLoader: payload };
    }
    case SET_DEFAULT_SELFIE: {
      return { ...state, defaultSelfie: payload };
    }
    case SET_API_IMAGE: {
      return { ...state, apiImage: payload }
    }
    case SET_LOADED_SCENE: {
      return { ...state, loadedScene: payload }
    }
    case SET_AVATAR_ASSETS: {
      return { ...state, avatarAssets: payload }
    }
    case SET_IS_ANONYMOUS: {
      return { ...state, isAnonymous: payload }
    }
    case SET_OLD_USER_ID: {
      return { ...state, oldUserId: payload }
    }
    case SET_TOKEN: {
      return { ...state, token: payload }
    }
    case SET_UID: {
      return { ...state, uid: payload }
    }
    case SET_SESSION: {
      return { ...state, session: payload }
    }
    case SET_INSTRUCTION_LIST: {
      return { ...state, instructionCheckList: payload }
    }
    case SET_SIZE_RECOMMENDATIONS: {
      return { ...state, sizeRecommendations: payload }
    }
    case SET_FIRST_TIME_CREATION: {
      return { ...state, firstTimeCreation: payload }
    }
    case ENABLE_ANALYTICS: {
      return { ...state, enableAnalytics: payload }
    }
    case SET_CART_DETAILS: {
      return { ...state, cartDetails: payload }
    }
    case SET_RELATED_BRANDS: {
      return { ...state, relatedBrands: payload }
    }
    case RESET_AVATAR_STATE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
