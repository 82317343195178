import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import get from 'lodash/get';
import BackButton from 'components/BackButton';
import FloatingButtonBar from 'components/FloatingButtonBar';
import Button from 'components/Button';
import Follow from 'assets/icons/instructions/Follow.svg';
import ShadowOnFace from 'assets/icons/instructions/ShadowOnFace.svg';
import HairFace from 'assets/icons/instructions/HairFace.svg';
import ShutEyes from 'assets/icons/instructions/ShutEyes.svg';
import SideFace from 'assets/icons/instructions/SideFace.svg';
import FemaleBody from 'assets/images/Female.png';
import MaleBody from 'assets/images/Male.png';
import CustomCarousel from 'components/CustomCarousel';
import { DEFAULT_AVATARS } from "configs/RendererConfig.js";
import { useDispatch, useSelector } from 'react-redux';
import UploadImage from 'containers/uploadImage';
import { actionsCreator } from 'redux/actions/actionsCreator';
import { ACTION_TYPES, CUSTOMISE_STEPS, MEASUREMENT_SCREEN_TYPE } from 'utils/constants';
import { userServiceAPI } from 'api';
import { isTouchFriendly } from 'utils';
import { ToggleButton, ToggleButtonGroup, styled } from '@mui/material';
import BottomBar from 'components/BottomBar/BottomBar';
import Loader from 'components/Loader';
import { EVENT_ACTIONS, EVENT_CATEGORIES } from 'appAnalytics/eventCategories';
import MixPanel from "appAnalytics/mixPanel";

const STEP = CUSTOMISE_STEPS[MEASUREMENT_SCREEN_TYPE.FACE_SELECTION];

const STEP_LABELS = {
    [STEP.TAKE_PHOTO]: {
        heading: 'Take a Photo!',
        text: "We'll Use It To Create Your Avatar",
    },
    [STEP.SELECT_FROM_SAMPLES]: {
        heading: 'Pick a Face!',
        text: "We'll Use It To Create Your Avatar",
    },
    [STEP.PHOTO_SELECTED]: {
        heading: 'Like Your Selfie!',
        text: "We'll Use It To Create Your Avatar",
    }
}

const AVOID_INSTRUCTIONS = [{
    icon: ShadowOnFace,
    text: 'Shadows'
}, {
    icon: HairFace,
    text: 'Hair'
}, {
    icon: ShutEyes,
    text: 'Shut Eyes',
}, {
    icon: SideFace,
    text: 'Side Face'
}];


const mapStateToProps = state => {
    const imageUrl = get(state, "avatarReducer.imageUrl", "");
    const selfie = get(state, "avatarReducer.selfie", "");
    const isDefaultAvatar = get(state, "avatarReducer.isDefaultAvatar", false);
    const defaultUserProfile = get(state, 'avatarReducer.defaultUserProfile');
    const uploadLoader = get(state, 'avatarReducer.uploadLoader', false);
    const product = get(state, "avatarReducer.product", {});
    const loadedScene = get(state, "avatarReducer.loadedScene");
    return {
        imageUrl,
        selfie,
        userProfile: get(state, "avatarReducer.userProfile", {}),
        isDefaultAvatar,
        defaultUserProfile,
        uploadLoader,
        product,
        loadedScene
    };
};

const FaceSelection = (props) => {
    const dispatch = useDispatch();
    const ref = useRef(null);
    const { actionType = ACTION_TYPES.NONE, loader, setLoader, setActiveStepIndex, basicInfo, errorMessage, setErrorMessage } = props;
    const { imageUrl, userProfile, selfie, defaultUserProfile, isDefaultAvatar, product, uploadLoader } = useSelector(mapStateToProps);
    // const [nextDisabled, setNextDisabled] = useState(false);
    const userProfileData = isDefaultAvatar ? defaultUserProfile : userProfile;
    const [step, setStep] = useState(props.actionType === ACTION_TYPES.DEFAULT && imageUrl ? STEP.PHOTO_SELECTED : STEP.TAKE_PHOTO);
    // const [step, setStep] = useState(STEP.TAKE_PHOTO);
    const [selectedSampleAvatar, setSelectedSampleAvatar] = useState(DEFAULT_AVATARS[basicInfo.gender][0]);

    const backHandler = () => {
        let eventCategory = EVENT_CATEGORIES.TAKE_PHOTO;
        switch (step) {
            case STEP.PHOTO_SELECTED:
                if (actionType === ACTION_TYPES.SELFIE) {
                    props.backHandler();
                } else {
                    setStep(STEP.TAKE_PHOTO);
                    setActiveStepIndex(0);
                    dispatch(actionsCreator.SET_AVATAR_IMAGE(''));
                    dispatch(actionsCreator.SET_SELFIE_FILE(''));
                }
                eventCategory = EVENT_CATEGORIES.LIKE_YOUR_SELFIE;
                break;
            case STEP.SELECT_FROM_SAMPLES:
                setStep(STEP.TAKE_PHOTO);
                setSelectedSampleAvatar(DEFAULT_AVATARS[basicInfo.gender][0]);
                setActiveStepIndex(0)
                eventCategory = EVENT_CATEGORIES.PICK_FACE;
                break;
            default:
                props.backHandler();
                setActiveStepIndex(0)
                break;
        }
        MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS.BACK_BUTTON, { actionType });
    }


    const uploadImage = () => {
        if (ref && ref.current) {
            ref?.current && ref.current.click();
            setErrorMessage('');
        }
    };
    const onSuccessUplaod = () => {
        setStep(STEP.PHOTO_SELECTED);
        MixPanel.buttonClicked(EVENT_CATEGORIES.LIKE_YOUR_SELFIE, EVENT_ACTIONS.PHOTO_UPLOADED, { actionType });
        setSelectedSampleAvatar(DEFAULT_AVATARS[basicInfo.gender][0]);
    }
    const secondaryActionHandler = () => {
        if (!uploadLoader) {
            setErrorMessage('');
            switch (step) {
                case STEP.TAKE_PHOTO: {
                    setStep(STEP.SELECT_FROM_SAMPLES);
                    setSelectedSampleAvatar(DEFAULT_AVATARS[basicInfo.gender][0]);
                    setActiveStepIndex(0);
                    MixPanel.buttonClicked(EVENT_CATEGORIES.TAKE_PHOTO, EVENT_ACTIONS.SELECT_FROM_SAMPLES, { actionType });
                    break;
                }
                case STEP.PHOTO_SELECTED: {
                    setActiveStepIndex(0);
                    MixPanel.buttonClicked(EVENT_CATEGORIES.LIKE_YOUR_SELFIE, EVENT_ACTIONS.RETAKE_SELFIE, { actionType });
                    onRemoveHandler();
                    break;
                }
                default:
                    break;
            }
        }
    }
    const nextHandler = () => {
        if (!uploadLoader) {
            setErrorMessage('');
            const { height, weight, userAvatar = {} } = userProfileData;
            const { hip, chest, waist } = userAvatar;
            const { gender } = basicInfo;
            switch (step) {
                case STEP.SELECT_FROM_SAMPLES: {
                    dispatch(actionsCreator.SET_AVATAR_IMAGE(selectedSampleAvatar.url));
                    if (actionType === ACTION_TYPES.DEFAULT) {
                        props.nextHandler();
                        setActiveStepIndex(1);
                    } else {
                        setLoader(true);
                    }
                    const postData = new FormData();
                    postData.append("defaultSelfiePath", selectedSampleAvatar.value);
                    postData.append("weight", weight);
                    postData.append("height", height);
                    postData.append("hip", hip);
                    // postData.append("highHip", highHipVal);
                    postData.append("chest", chest);
                    postData.append("waist", waist);
                    postData.append("gender", gender);
                    // postData.append("name", name);
                    postData.append("version", 'default');
                    postData.append("env", "qa");
                    MixPanel.buttonClicked(EVENT_CATEGORIES.PICK_FACE, EVENT_ACTIONS.SAMPLE_PHOTO_SELECTED, { selectedSampleAvatar, actionType })
                    createAvatarHandler(postData);
                    break;
                }
                case STEP.TAKE_PHOTO: {
                    MixPanel.buttonClicked(EVENT_CATEGORIES.TAKE_PHOTO, EVENT_ACTIONS.UPLOAD_SELFIE, { actionType });
                    uploadImage();
                    break;
                }
                case STEP.PHOTO_SELECTED: {
                    if (actionType === ACTION_TYPES.DEFAULT) {
                        props.nextHandler();
                        setActiveStepIndex(1);
                    } else {
                        setLoader(true);
                    };
                    const avatarImageUrl = get(userAvatar, 'imageUrl', '');
                    if (selfie && imageUrl && imageUrl !== avatarImageUrl) {
                        const postData = new FormData();
                        postData.append("weight", weight);
                        postData.append("height", height);
                        postData.append("hip", hip);
                        // postData.append("highHip", highHipVal);
                        postData.append("chest", chest);
                        postData.append("waist", waist);
                        postData.append("gender", gender);
                        // postData.append("name", name);
                        postData.append("version", "temp");
                        postData.append("env", "qa");
                        postData.append("selfie", selfie);
                        MixPanel.buttonClicked(EVENT_CATEGORIES.LIKE_YOUR_SELFIE, EVENT_ACTIONS.PHOTO_SELECTED, { imageUrl, actionType });
                        createAvatarHandler(postData);
                    } else {
                        setLoader(false);
                        if (actionType !== ACTION_TYPES.DEFAULT) {
                            props.backHandler();
                        }
                        MixPanel.buttonClicked(EVENT_CATEGORIES.LIKE_YOUR_SELFIE, EVENT_ACTIONS.PHOTO_SELECTED, { imageUrl: avatarImageUrl, actionType });
                    }

                    break;
                }
                default:
                    break;
            }
        }
    };

    const createAvatarHandler = async (postData) => {
        props.setCallUpdateAvatar(false);
        // call create job api only if avatar is updated
        props.setSelfieResponse(null);
        try {
            const response = await userServiceAPI.createAvatar(postData);
            const jobId = get(response, "data.jobId");
            actionType !== ACTION_TYPES.DEFAULT && props.setCallUpdateAvatar(true);
            if (jobId) {
                props.getSelfieJobStatus(jobId);
            } else {
                setLoader(false);
            }
        } catch (e) {
            setErrorMessage('Something went wrong. Please try again later.');
            // setSelfieLoading(false);
            setLoader(false);
        }
    };

    const onDefaultAvatarSelect = (data) => {
        setSelectedSampleAvatar(data);
    }

    const onRemoveHandler = e => {
        const { userAvatar } = isDefaultAvatar ? defaultUserProfile : userProfile;
        let imageUrl = get(userAvatar, 'imageUrl', '');
        dispatch(actionsCreator.SET_AVATAR_IMAGE(imageUrl));
        dispatch(actionsCreator.SET_SELFIE_FILE(""));
        setStep(STEP.TAKE_PHOTO);
    };

    const renderFaceSelectionsFields = () => {
        const defaultAvatarList = DEFAULT_AVATARS[basicInfo.gender];
        switch (step) {
            case STEP.TAKE_PHOTO: {
                return <div style={{ padding: '0.5em 1em' }}>
                    <div className='FaceSelection__Instructions'>
                        <div>Take the Perfect Selfie</div>
                        <div className='FaceSelection__Instructions__Follow'>
                            <img src={Follow} alt="Face" />
                        </div>
                        <div className='FaceSelection__Instructions__Avoid'>
                            <div className='FaceSelection__Instructions__Avoid__Label'>Avoid</div>
                            {AVOID_INSTRUCTIONS.map(instruction => {
                                return <div key={instruction.text} className='FaceSelection__Instructions__InstructionItem'>
                                    <img src={instruction.icon} alt={instruction.text} />
                                    <div>{instruction.text}</div>
                                </div>
                            })}
                        </div>
                        <FloatingButtonBar style={{ background: 'none' }}>
                            <Button type='white-black' onClick={secondaryActionHandler}>
                                Sample Avatars
                            </Button>
                            <Button type='black-white' onClick={nextHandler} isLoading={uploadLoader}>
                                {isTouchFriendly ? 'Take Selfie' : 'Upload Selfie'}
                            </Button>
                        </FloatingButtonBar>
                    </div>
                </div>
            }
            case STEP.SELECT_FROM_SAMPLES: {
                return <div style={{ padding: '1em' }}>
                    <div className='FaceSelection__Instructions'>
                        <div>Here Are Our Avatars</div>
                        <CustomCarousel data={defaultAvatarList} handleClickOnBannerAction={onDefaultAvatarSelect} />
                        <FloatingButtonBar style={{ background: 'none' }}>
                            <Button type='black-white' onClick={nextHandler} isLoading={loader}>
                                Next
                            </Button>
                        </FloatingButtonBar>
                    </div>
                </div>
            }
            case STEP.PHOTO_SELECTED:
                return <FloatingButtonBar style={{ background: 'none' }}>
                    <Button type='white-black' onClick={secondaryActionHandler} isLoading={loader}>
                        No? Retake!
                    </Button>
                    <Button type='black-white' onClick={nextHandler} isLoading={loader}>
                        Yes! Next
                    </Button>
                </FloatingButtonBar>
            default:
                break;
        }
    }

    const getSelectedFace = () => {
        switch (step) {
            case STEP.SELECT_FROM_SAMPLES:
                return selectedSampleAvatar?.url || '';
            case STEP.PHOTO_SELECTED:
                return imageUrl;
            default:
                return "";
        }
    }

    const { heading, text } = STEP_LABELS[step];
    const selectedFace = getSelectedFace();

    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '&.MuiToggleButtonGroup-root': {
            boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',
            borderRadius: '25px',
            background: '#EEEEEE',
        },
        '& .MuiToggleButtonGroup-grouped': {
            // margin: theme.spacing(0.5),
            lineHeight: 1,
            textTransform: 'none',
            borderRadius: '25px',
            // border: '1px solid',
            // border: 0,
            // borderLeft: '1px solid #000',
            padding: '0.5em',
            fontSize: '0.8em',
            background: '#EEEEEE',
            // '&:first-of-type': {
            //   borderRight: '1px solid #000',
            // },
            '&:hover': {
                // background: '#2C2C2C',
                background: '#EEEEEE',
                color: '#000'
            },

            '&.MuiButtonBase-root': {
                width: '6em',
                borderRight: '0',
            },
            '&.Mui-selected': {
                color: '#FFF',
                background: '#333333',
                borderRadius: '25px',
                position: 'relative',
                zIndex: 1,
                '&:hover': {
                    // background: '#2C2C2C',
                    background: '#333333',
                    color: '#FFF'
                }
            },
            // '&.Mui-disabled': {
            //   border: 0,
            // }
        },
    }));

    return (
        <>
            {loader ? <div
                style={{
                    background: "rgba(255,255,255, 1)",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    zIndex: "10",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0
                }}
            >
                <Loader className={"TopLoader"} brand={get(product, 'brand', '')} />
            </div> :
                <>
                    <div className={`FaceSelection__BodyPlaceHolder ${selectedFace ? 'selected' : ''} ${step === STEP.TAKE_PHOTO && !product?.showGenderSelection ? '' : 'background'}`}>
                        {!selectedFace ? <motion.div
                            key="FaceCircle"
                            className={`FaceSelection__FaceCircle`}
                            initial={{ opacity: 0, scale: 0.8, x: '-50%', y: '-50%', }}
                            animate={{ opacity: 1, scale: 1, x: '-50%', y: '-50%', transition: { delay: 0.5, duration: 0.3, type: 'tween' } }}
                        >
                        </motion.div> :
                            <motion.div
                                className='FaceSelection__SelectedFace'
                                initial={{ opacity: 0, scale: 0.8, x: '-50%', y: '-50%', }}
                                animate={{ opacity: 1, scale: 1, x: '-50%', y: '-50%', transition: { delay: 0, duration: 0.3, type: 'tween' } }}
                            >
                                <motion.div
                                    key="SelectedFace"
                                    className={`FaceSelection__SelectedFace__Circle`}
                                >
                                    <img src={selectedFace} alt="Selected Face" width="100%" height="100%"
                                        style={{ objectFit: 'cover' }}
                                    />
                                </motion.div>
                                {errorMessage && <div className='FaceSelection__Error'>{errorMessage}</div>}
                            </motion.div>
                        }

                        {step === STEP.TAKE_PHOTO && product.showGenderSelection &&
                            <img className={'FaceSelection__BodyPlaceHolder__BodyImg'} src={basicInfo.gender === 'male' ? MaleBody : FemaleBody} alt={basicInfo.gender === 'male' ? "Male" : "Female"} />
                        }
                    </div >

                    <div style={{ padding: '0.5em 1em', position: 'absolute', zIndex: 1 }}>
                        <BackButton onClick={() => backHandler()} />
                    </div>
                    <div className='Dopplr_Customize__Title' style={{ top: '5%' }}>
                        <div className='Dopplr_Customize__Heading'>{heading}</div>
                        <div className='Dopplr_Customize__Text'>{text}</div>
                        {step === STEP.TAKE_PHOTO && product?.showGenderSelection &&
                            <div className="FaceSelection__GenderSelection">
                                <div className="FaceSelection__GenderSelection__Title">Select Gender</div>
                                <StyledToggleButtonGroup
                                    value={basicInfo.gender}
                                    exclusive
                                    onChange={(e, value) => props.onBasicInfoChangeHandler(e, "gender", value)}
                                    size="small"
                                >
                                    <ToggleButton
                                        value={"male"}>
                                        Male
                                    </ToggleButton>
                                    <ToggleButton
                                        value={"female"}>
                                        Female
                                    </ToggleButton>
                                </StyledToggleButtonGroup>
                            </div>
                        }
                    </div>
                    <BottomBar
                        keyName={step}
                        className='FaceSelection'
                        style={{ paddingBottom: '0' }}
                    >
                        {renderFaceSelectionsFields()}
                    </BottomBar>

                    <UploadImage ref={ref} onSuccessUplaod={onSuccessUplaod} />
                </>}
        </>
    )
}

export default FaceSelection;
