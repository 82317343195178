import get from 'lodash/get';
import { Vector3 } from "@babylonjs/core/Legacy/legacy";
import { clamp } from "utils";
import { GARMENT_TYPE } from './constants';

const getFitInsight = (bodyPart, value) => {
    if (
        (value >= bodyPart && value - 3 <= bodyPart) ||
        (value <= bodyPart && value + 3 >= bodyPart)
    ) {
        return "normal"
    } else if (value > bodyPart) {
        if (value - bodyPart < 10) {
            return "slightlyloose"
        }
        return "loose";
    } else if (bodyPart - value < 10) {
        return "slightlytight"
    }
    return "tight";
};

export const getGarmentValue = (fitInsights, userData, heatmapSize) => {
    let { chest: chestValueUser, waist: waistValueUser, hip: hipValueUser, highHip: highHipValueUser } = userData;
    const key = heatmapSize || 'value';
    let chestValueGarment = get(fitInsights, `chest.${key}`);
    let waistValueGarment = get(fitInsights, `waist.${key}`);
    let hipValueGarment = get(fitInsights, `hip.${key}`);
    let highHipValueGarment = get(fitInsights, `highHip.${key}`);
    const result = {
        "chestMsg": getFitInsight(chestValueUser, chestValueGarment),
        "waistMsg": getFitInsight(waistValueUser, waistValueGarment),
        "hipMsg": getFitInsight(hipValueUser, hipValueGarment),
        "highHipMsg": getFitInsight(highHipValueUser, highHipValueGarment),
        "chestValueGarment": chestValueGarment,
        "waistValueGarment": waistValueGarment,
        "hipValueGarment": hipValueGarment,
        "highHipValueGarment": highHipValueGarment
    };
    return result
}
export const getGarmentValues = (fitInsights, userData, heatmapSize) => {
    let { chest: chestValueUser, waist: waistValueUser, hip: hipValueUser, highHip: highHipValueUser } = userData;
    const key = heatmapSize || 'value';
    let chestValueGarment = get(fitInsights, `chest.${key}`);
    let waistValueGarment = get(fitInsights, `waist.${key}`);
    let hipValueGarment = get(fitInsights, `hip.${key}`);
    let highHipValueGarment = get(fitInsights, `highHip.${key}`);
    return {
        chestMsg: getFitInsight(chestValueUser, chestValueGarment),
        waistMsg: getFitInsight(waistValueUser, waistValueGarment),
        hipMsg: getFitInsight(hipValueUser, hipValueGarment),
        highHipMsg: getFitInsight(highHipValueUser, highHipValueGarment),
        chestValueGarment,
        waistValueGarment,
        hipValueGarment,
        highHipValueGarment
    }
}

export const mixColors = (colorA, colorB, strength) => {
    const r = (colorA[0] * strength) + (colorB[0] * (1 - strength))
    const g = (colorA[1] * strength) + (colorB[1] * (1 - strength))
    const b = (colorA[2] * strength) + (colorB[2] * (1 - strength))
    const a = (colorA[3] * strength) + (colorB[3] * (1 - strength))
    return [r, g, b, a];
};

export const bodyAreasLimits = {
    "Chest": { min: new Vector3(0, 0, 0), max: new Vector3(0, 0, 0), minXFactor: 1.2, maxXFactor: 1.2, minYFactor: 1, maxYFactor: 1.1, minZFactor: 1, maxZFactor: 1.2 },
    "Armpits": { min: new Vector3(0, 0, 0), max: new Vector3(0, 0, 0), minXFactor: 1, maxXFactor: 1, minYFactor: 1, maxYFactor: 1, minZFactor: 0.9, maxZFactor: 0.9 },
    "Shoulders": { min: new Vector3(0, 0, 0), max: new Vector3(0, 0, 0), minXFactor: 1, maxXFactor: 1, minYFactor: 1, maxYFactor: 1, minZFactor: 1, maxZFactor: 1 },
    "Waist": { min: new Vector3(0, 0, 0), max: new Vector3(0, 0, 0), minXFactor: 1.1, maxXFactor: 1.1, minYFactor: 1, maxYFactor: 1, minZFactor: 1.1, maxZFactor: 1.2 },
    "Torso": { min: new Vector3(0, 0, 0), max: new Vector3(0, 0, 0), minXFactor: 1.2, maxXFactor: 1.2, minYFactor: 1, maxYFactor: 1, minZFactor: 0.9, maxZFactor: 1.2 },
    "LoveHandles": { min: new Vector3(0, 0, 0), max: new Vector3(0, 0, 0), minXFactor: 1.1, maxXFactor: 1.1, minYFactor: 1, maxYFactor: 1, minZFactor: 1.1, maxZFactor: 1.2 },
    "Bums": { min: new Vector3(0, 0, 0), max: new Vector3(0, 0, 0), minXFactor: 1.1, maxXFactor: 1.1, minYFactor: 1, maxYFactor: 1, minZFactor: 1.1, maxZFactor: 1.1 },
    "WaisTorso": { min: new Vector3(0, 0, 0), max: new Vector3(0, 0, 0), minXFactor: 1.1, maxXFactor: 1.1, minYFactor: 1, maxYFactor: 1, minZFactor: 1.1, maxZFactor: 1.1 }
}
export const isVertexWithinBodyArea = (vertex, area) => {
    return (vertex.x > bodyAreasLimits[area].min.x * bodyAreasLimits[area].minXFactor)
        && (vertex.x < bodyAreasLimits[area].max.x * bodyAreasLimits[area].maxXFactor)
        && (vertex.y > bodyAreasLimits[area].min.y * bodyAreasLimits[area].minYFactor)
        && (vertex.y < bodyAreasLimits[area].max.y * bodyAreasLimits[area].maxYFactor)
        && (vertex.z > bodyAreasLimits[area].min.z * bodyAreasLimits[area].minZFactor)
        && (vertex.z < bodyAreasLimits[area].max.z * bodyAreasLimits[area].maxZFactor)
}

export const blendColorsinYAxis = (minY, maxY, vertex, clampedStrength, blendHeightFactor, direction = 0) => { // direction : { -1: negative, +1: positive, 0: both}
    const lowerlimitY = minY + (maxY - minY) * blendHeightFactor;
    const upperlimitY = maxY - (maxY - minY) * blendHeightFactor;

    if ((direction !== -1) && (vertex.y > upperlimitY)) {
        const factor = (maxY - vertex.y) / ((maxY - minY) * blendHeightFactor * 2)
        clampedStrength = (1 - factor);
    }
    else if ((direction !== 1) && (vertex.y < lowerlimitY)) {
        const factor = (vertex.y - minY) / ((maxY - minY) * blendHeightFactor * 2)
        clampedStrength = (1 - factor);
    }
    return clampedStrength;
}

export const blendColorsinXAxis = (minX, maxX, vertex, clampedStrength, blendWidthFactor) => {
    const lowerlimitX = minX + (maxX - minX) * blendWidthFactor
    const upperlimitX = maxX - (maxX - minX) * blendWidthFactor

    if (vertex.x > upperlimitX) {
        const factor = (maxX - vertex.x) / ((maxX - minX) * blendWidthFactor * 2)
        clampedStrength = (1 - factor)
    }
    else if (vertex.x < lowerlimitX) {
        const factor = (vertex.x - minX) / ((maxX - minX) * blendWidthFactor * 2)
        clampedStrength = (1 - factor)
    }
    return clampedStrength
}

export const getFitIndicatorValue = (fitInsights, userData, garmentType, heatmapSize) => {
    const { chestValueGarment, waistValueGarment, hipValueGarment, highHipValueGarment } = getGarmentValues(fitInsights, userData, heatmapSize);
    const { chest: chestValueUser, waist: waistValueUser, hip: hipValueUser, highHip: highHipValueUser } = userData;
    const chestDifference = clamp(chestValueGarment - chestValueUser, -15, 15);
    const waistDifference = clamp(waistValueGarment - waistValueUser, -15, 15);
    const hipDifference = clamp(hipValueGarment - hipValueUser, -15, 15);
    const highHipDifference = clamp(highHipValueGarment - highHipValueUser, -15, 15);
    const range = 15 * 1.414;
    let fitIndicationValue = ((3 * chestDifference) + waistDifference) / (4 * range);  //euclidean ? (chestDifference + waistDifference) / (euclidean * 2) : 0;
    fitIndicationValue = clamp(fitIndicationValue, -0.9, 0.9);
    if (garmentType === GARMENT_TYPE.BOTTOM) {
        fitIndicationValue = (3 * highHipDifference + hipDifference) / (4 * range);
        fitIndicationValue = clamp(fitIndicationValue, -0.9, 0.9);
    }
    return fitIndicationValue;
}

export const getFitIndicatorValueBH = (fitInsights, userData, garmentType, heatmapSize, plotShare) => {
    const { chestValueGarment, waistValueGarment, hipValueGarment, highHipValueGarment } = getGarmentValues(fitInsights, userData, heatmapSize);
    const { chest: chestValueUser, waist: waistValueUser, hip: hipValueUser, highHip: highHipValueUser } = userData;
    const chestDifference = chestValueGarment - chestValueUser;
    // const waistDifference = clamp(waistValueGarment - waistValueUser, -15, 15);
    // const hipDifference = clamp(hipValueGarment - hipValueUser, -15, 15);
    const highHipDifference = highHipValueGarment - highHipValueUser;
    let fitIndicationValue = ((chestDifference / 2.54) * plotShare) //euclidean ? (chestDifference + waistDifference) / (euclidean * 2) : 0;
    if (garmentType === GARMENT_TYPE.BOTTOM) {
        fitIndicationValue = (highHipDifference / 2.54) * plotShare;
    }
    return fitIndicationValue;
}

export const getMixedColorForBodyArea = (firstColor, secondColor, area, vertex, strengthFactor) => {
    let minX = bodyAreasLimits[area].min.x * bodyAreasLimits[area].minXFactor;
    let maxX = bodyAreasLimits[area].max.x * bodyAreasLimits[area].maxXFactor;
    let minY = bodyAreasLimits[area].min.y; // * bodyAreasLimits[area].minYFactor
    let maxY = bodyAreasLimits[area].max.y; // * bodyAreasLimits[area].maxYFactor
    let minZ = bodyAreasLimits[area].min.z; // * bodyAreasLimits[area].minZFactor
    let maxZ = bodyAreasLimits[area].max.z * bodyAreasLimits[area].maxZFactor;
    if (area === "WaisTorso") {
        minY = (bodyAreasLimits["Waist"].min.y + bodyAreasLimits["Waist"].min.y) / 2;
        maxY = (bodyAreasLimits["Torso"].max.y + bodyAreasLimits["Waist"].max.y) / 2;
        minX = (bodyAreasLimits["Torso"].min.x + bodyAreasLimits["Waist"].min.x) / 2;
        maxX = (bodyAreasLimits["Torso"].max.x + bodyAreasLimits["Waist"].max.x) / 2;
        minZ = bodyAreasLimits["Torso"].min.z;
        maxZ = bodyAreasLimits["Waist"].max.z;
    }

    let strengthZ = Math.abs((vertex.z - (minZ + maxZ) / 2) / (maxZ - minZ));
    let strengthY = Math.abs((vertex.y - (minY + maxY) / 2) / (maxY - minY));
    let strengthX = Math.abs((vertex.x - (minX + maxX) / 2) / (maxX - minX));

    let clampedStrength = clamp((strengthX + strengthY + strengthZ) / strengthFactor, 0, 1);


    // Blending the colors on the edges to give smooth transition between regions
    const directionY = (area === "Chest") ? -1 : 0;    // direction : { -1: negative, +1: positive, 0: both}

    const clampedStrengthY = blendColorsinYAxis(minY, maxY, vertex, clampedStrength, 0.2, directionY);
    const clampedStrengthX = blendColorsinXAxis(minX, maxX, vertex, clampedStrength, 0.2);
    clampedStrength = Math.max(clampedStrengthX, clampedStrengthY);

    let mixedColor = mixColors(firstColor, secondColor, clampedStrength);

    return mixedColor;
}