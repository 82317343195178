import HalfCard from 'components/HalfCard';
import React from 'react';
import "./CatalogList.scss";
import { IFRAME_ACTIONS } from 'actions/IframeActions';
import MixPanel from 'appAnalytics/mixPanel';
import { EVENT_ACTIONS, EVENT_CATEGORIES } from 'appAnalytics/eventCategories';

const CatalogList = ({ showCatalogList, setShowCatalogList, catalogList = [], selectedProduct, loading, eventCategory }) => {

    const handleProductSelection = (product) => {
        if (!loading) {
            MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS.NEW_PRODUCT_SELECTED, {...product});
            window.parent.postMessage({ message: IFRAME_ACTIONS.NEW_PRODUCT_SELECTED, value: product }, "*");
        }
    }
    return (
        <HalfCard
            visible={showCatalogList}
            key={'catalogList'}
            onClose={() => setShowCatalogList(false)}
            showCloseButton={true}
            showHeader={true}
            title={"Catalog List"}
            closeOnBackgroundClick={true}
            whiteBackground={true}
            bodyClassName={"Dopplr_CatalogList"}
            wrapperClassName={"Dopplr_CatalogList_Wrapper"}
            zIndex={loading ? -1 : 1}
        >
            {/* <div className='Dopplr_CatalogList'> */}
            {catalogList.map((product, i) => {
                const isSelected = selectedProduct && selectedProduct.id === product.id;
                return <div
                    key={i}
                    onClick={() => handleProductSelection(product)}
                    className={`ProductCard`}
                >
                    <div
                        className={`ProductCard__Image ${isSelected ? "selected" : "overlay"
                            }`}
                    >
                        <img src={product.productImage || product.src || ''} alt={product.productTitle} />
                    </div>
                    <div
                        className={`ProductCard__Description ${isSelected ? "selectedText" : ""
                            }`}
                    >
                        {/* {product.name} */}
                    </div>
                </div>
            })}
            {/* </div> */}
        </HalfCard>
    )
}

export default CatalogList;
