import React, { useEffect, useState } from 'react';
import { useDeviceOrientation } from './useDeviceOrientation';
import OrientationSwitcher from './OrientationSwitcher';
import AllowFrame from './AllowFrame';
import AllowFrameTF from './AllowFrameTF';
import HalfCard from 'components/HalfCard';
import Button from 'components/Button';
import { isTouchFriendly } from 'utils';
import FloatingButtonBar from 'components/FloatingButtonBar';
import { SELFIE_STATUS } from 'utils/constants';
const MESSAGES = {
  [SELFIE_STATUS.VIDEO_PERMISSION_DENIED]: 'Please allow camera to proceed further.',
  [SELFIE_STATUS.ORIENTATION_PERMISSION_DENIED]: 'Please allow browser to use orientation',
}
const OrientationInfo = ({ onFlagChange, showOrientationInfo, setShowOrientationPermission, showOrientationPermission, onClose, status }) => {
  const [gyroValue, setGyroValue] = useState(180);
  const { orientation, requestAccess, revokeAccess } = useDeviceOrientation();
  const gyroscopePointerPosition = (value) => {
    const result = (value * 360) / 180;
    let position = result;

    if (result < 0) {
      position = 0;
    }

    if (result > 360) {
      position = 360;
      return position;
    }
    return position;
  }
  const getGyroValue = (orientation) => {
    const gyroValue = orientation ? gyroscopePointerPosition(orientation.beta) : null;
    setGyroValue(gyroValue);
  }
  const isGryoRange = (value) => {
    if (!value) {
      return null;
    }
    if (value < 220 && value > 145) {
      onFlagChange(true)
      return true;
    } else {
      onFlagChange(false)
      return true;
    }
  }

  const onToggle = async toggleState => {
    try {
      if (toggleState) {
        const response = await requestAccess();
        setShowOrientationPermission(false)
      } else {
        await revokeAccess();
      }
    } catch (e) {
      if (!isTouchFriendly) {
        setShowOrientationPermission(true);
      } else {
        setShowOrientationPermission(false);
      }
      console.log(e);
    }
  };

  useEffect(() => {
    getGyroValue(orientation);
  }, [orientation, getGyroValue]);

  const message = MESSAGES[status] || ''

  if (showOrientationInfo)
    return (
      <>
        {!showOrientationPermission && isGryoRange(gyroValue) && <AllowFrame
          info={false}
          isLastPhoto={true}
          gyroscopePosition={gyroValue} å
        />}
        {/* <OrientationSwitcher
          onToggle={onToggle}
          labelOff="Show orientation angles"
          labelOn="Hide orientation angles"
        /> */}
        <HalfCard
          visible={showOrientationPermission}
          key={'permssion'}
          // onClose={() => setShowOrientationPermission(false)}
          // showCloseButton={true}
          showHeader={true}
          title={"Permission needed"}
          // closeOnBackgroundClick={true}
          whiteBackground={true}
        >
          {message && <div style={{ padding: '1em' }}>{message}</div>}
          <FloatingButtonBar>
            <Button onClick={onClose} type={'white-black'}>Cancel</Button>
            {status === SELFIE_STATUS.ORIENTATION_PERMISSION_DENIED && <Button onClick={onToggle}>Use Orientation </Button>}
          </FloatingButtonBar>
        </HalfCard>
      </>
    );
  return null;
};

export default OrientationInfo;

