import React from "react";
import { useDispatch } from "react-redux";
import { actionsCreator } from "redux/actions/actionsCreator";
import { getURLParams } from "utils";
import imageCompression from "browser-image-compression";
import loadImage from "blueimp-load-image";

const UploadImage = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const setAvatarImage = payload =>
    dispatch(actionsCreator.SET_AVATAR_IMAGE(payload));
  const setSelfieFile = payload =>
    dispatch(actionsCreator.SET_SELFIE_FILE(payload));
  const compressAndUpload = e => {
    dispatch(actionsCreator.UPLOAD_LOADER(true));
    const file = e.target.files[0];
    if (file) {
      const fileSize = (file.size / 1048576).toFixed(2);
      console.log("File size", fileSize);
      if (fileSize < 0.15) {
        console.log("File size -> less than 1 MB");
        handleAttachments(file);
      } else {
        const options = {
          useWebWorker: true,
          maxSizeMB: 0.6,
        };
        imageCompression(file, options)
          .then(compressedFile => {
            console.log('compressedFile -> instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile  -> size ${compressedFile.size / 1024 / 1024} MB`);
            handleAttachments(compressedFile);
          })
          .catch(e => {
            console.error(e);
            console.log("Unable to compress file", file);
            handleAttachments(file);
            //toast.error("Error in uploading image. Please try again");
          });
      }
    }
  };
  const handleAttachments = async (file) => {
    try {
      // const file = e.target.files[0];
      //   const fileExt = getFileExtension(file.name);
      //   const defaultProfileId = get(user, 'profiles.0.id', '');
      //   const filePath = `UserUploadedPrescription/${defaultProfileId}/`;
      //   const fileName = `${filePath + 'defaultProfileId'}-${Date.now()}${fileExt}`;
      //   const postData = new FormData();
      //   postData.append('file', file, fileName);
      if (file) {
        //API call and redirect to next step
        // const response = await assetsAPI.uploadToS3Private(postData, this.onUploadProgress);
        // const url = get(response, 'data.file.url');
        // if (url) {
        //   addPrescription([url]);
        // }
        dispatch(actionsCreator.UPLOAD_LOADER(true));
        let data = await loadImage(file, { maxWidth: 600, canvas: true, orientation: 0, meta: true });
        data.image.toBlob(blob => {
          const objectUrl = URL.createObjectURL(blob);
          setAvatarImage(objectUrl);
          setSelfieFile(file);
          props.onSuccessUplaod && props.onSuccessUplaod();
          dispatch(actionsCreator.UPLOAD_LOADER(false));
        })
      }
    } catch (e) {
      console.error(e);
      dispatch(actionsCreator.UPLOAD_LOADER(false));
    }
  };
  return (
    <input
      id="attachment-camera"
      ref={ref}
      type="file"
      accept="image/png, image/jpeg, image/svg, image/jpg"
      // accept="image/*"
      hidden
      name=""
      onChange={e => {
        compressAndUpload(e);
      }}
    />
  );
});

export default UploadImage;
